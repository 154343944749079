import {Component, Input} from '@angular/core';
import {TranslatePipe} from "../../pipes/translate.pipe";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-pill',
  standalone: true,
  imports: [
    TranslatePipe,
    NgIf
  ],
  templateUrl: './pill.component.html',
  styleUrl: './pill.component.scss'
})
export class PillComponent {
  @Input() style: 'default'|'notification' = 'default';
}
