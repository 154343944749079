<div class="card shadow-sm w-border rounded-3 mb-2 notifications-card">
  <div class="card-body">
    <div class="d-flex-column">
      <div class="d-flex align-items-start">
        <div class="d-flex e-avatar e-avatar-circle e-avatar-large">
          <img *ngIf="notification.image" [ngSrc]="notification.image" [width]="60" [height]="60" [sizes]="'100vw, 100vw, 100vw'" [alt]="notification.name" priority="low" />
        </div>

        <div class="col ps-2">
          <div class="d-flex flex-wrap align-items-center">
            <app-headline [type]="'notification'" [title]="notification.name"></app-headline>
            <app-content [style]="'notificationDate'" [cssClass]="'mb-0'">{{ 'POSTEDAT' | translate }} {{ notification.created_at | date : 'medium'}}</app-content>
          </div>

          <p class="fs-verysmall text-muted mb-1" [innerHTML]="notification.message"></p>
          <app-content [cssClass]="'m-0'" [innerHTML]="notification.message"></app-content>

          <div class="row align-items-center gx-2">
            <div class="col-auto d-flex align-items-center">
              <app-pill [style]="'notification'">{{ notification.type | translate }}</app-pill>
            </div>
            <div class="col-auto flex-grow-1 text-end" *ngIf="notification.type !== 'chat'">
              <div class="dropdown">
                <div id="notifications-recent" data-bs-toggle="dropdown" aria-expanded="false">
                  <app-icon-badge [style]="'notification'" [icon]="'more_horiz'" [screenReaderText]="'SRMOREOPTIONS' | translate"></app-icon-badge>
                </div>
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="notifications-recent">
                  <li><app-link (click)="setRead()" [title]="('BELLMARKASREAD' | translate)" [style]="'dropdown'">{{ ('BELLMARKASREAD' | translate) }}</app-link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
